.container{
    width: 50%;
    display: grid;
    place-items: center;

    /* display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%); */
}
.maincontainer{
  width: 100%;
  /* display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%); */
  height: 100vb;
  display: grid;
  place-items: center;
  /* background-image: url("../images/loginbackground.jpg");
  background-size: cover; */
  padding-top: 5%;
}
.upwelltitle{
  width: 100%;
  /* height: 70px; */
  margin-top: 5px;
  background-color: white;
  color: #000;
  z-index: 9999;
  flex-wrap: wrap;
  margin-bottom: 5px;
  padding: 5px;
  position: absolute;
}
.logoStyling{
  width: 220px;
  height: 60px;
  padding-left: 50px;
}
.boxShadow{
    box-shadow: 2px 2px 7px #bbb;
    padding: 20px;
    width: 100%;
    max-width: 400px;
    text-align: center;
    border-radius: 10px;
    background-color: white;
}
.loginbuttonStyle{
  width: 100%;
  max-width: 200px;
  color: white;
  background: black;
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  font-size: 16px;
  background: linear-gradient(#000,#726e6e);
  border: none;
}
.log-type-title{
  font-weight: 500;
  font-size: 20px;
}

/* Report page styling Start*/
.subtitle {
  font-size: 18px;
  font-weight: 500;
}

.subtitle-pdf {
  font-size: 20px;
  font-weight: 600;
  padding: 10px 0px 10px 0px;
}
.display-batch {
  display: grid;
  place-items: center;
}
.download-img {
  padding: 0px;
  margin: 2px 0px 0px 15px;
  height: 18px;
  cursor: pointer;
}
.table{
  width: 100%;
  text-align: left;
  padding: 5px 5px 5px 0px;

  table-layout: fixed;
}

.tabletitle {
  padding: 5px 5px 5px 5px;
  text-align: center;
  background: #d2e3ea;
  font-size: 20px;
  font-weight: 600;
  /* margin-bottom: 10px; */
}

.table thead {
  background-color: antiquewhite;
  color: #c8dce3;
}

.table tbody tr:nth-child(even){
  background-color: #e9e9e9;
}

.table thead tr th {
  padding: 5px 10px;
}

.tdbreak {
  word-break: break-word;
  padding: 5px 5px 5px 14px;
  font-size: 12px;
}

.currentdata {
  font-weight: 700;
  color: #2768db;
}

.materialdata {
  padding: 0px 14px;
}


/* Report page styling End*/