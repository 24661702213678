.maincontainernew {
  width: 100%;
  height: 100vb;
  display: grid;
  place-items: center;
  /* display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  /* background-image: url("../images/newbatchbgimg.jpg");
  background-size: cover; */
  padding-top: 98px;
}

.containerStyle {
  /* display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  box-shadow: 2px 2px 7px #bbb;
  padding: 30px 20px;
  background-color: white;
  border-radius: 10px;
  margin: 5px;
}
.error{
  color: red;
  font-size: 14px;
  font-weight: 600;
}

.addTitle {
  font-size: 30px;
  font-weight: 600;
  /* padding: 21px 50px; */
  text-align: center;
  /* padding: 21px 150px 21px 0px; */
  flex: auto;
  display: grid;
  place-items: center;
  padding-right: 10%;
}
.addTitle-2 {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  flex: auto;
  display: grid;
  place-items: center;
  padding-bottom: 15px;
}


.buttonStyle {
  width: 100%;
  max-width: 170px;
  color: white;
  /* background: black; */
  border: none;
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  font-size: 16px;
  background-color: rgb(243, 165, 11);
  background: linear-gradient(#b40, orange);
}

.buttonStyle1 {
  width: 100%;
  max-width: 170px;
  color: white;
  /* background: black; */
  border: none;
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  font-size: 16px;
  background: linear-gradient(#740, gold);
}

.buttonStyle2 {
  width: 100%;
  max-width: 170px;
  color: white;
  /* background: black; */
  border: none;
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  font-size: 16px;
  background: linear-gradient(#258, #70daf4);
}

.buttonStyle3 {
  width: 100%;
  max-width: 170px;
  color: white;
  /* background: black; */
  border: none;
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  font-size: 16px;
  background: linear-gradient(#073, rgb(0, 255, 106));
}

.buttonStyle4 {
  width: 100%;
  max-width: 170px;
  color: white;
  /* background: black; */
  border: none;
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  font-size: 16px;
  background: linear-gradient(rgb(77, 0, 119), rgb(191, 0, 255));
}

.inputStyle {
  border: none;
  padding: 5px;
  outline: none;
}

.displayFlex {
  display: flex;
  flex-wrap: wrap;
}

.flexAuto {
  flex: 1 0 200px;
}

.paddingTop {
  padding-top: 20px;
  text-align: center;
}

.marginStyle {
  margin-bottom: 10px;
}

.scheduler-border {
  width: fit-content;
  padding: 0 7px;
  border-bottom: none;
  font-size: 14px;
  margin-bottom: -3px;
  font-weight: 600;
  color: black;
}

.maincontainerbiomass {
  width: 100%;
  height: 100vb;
  display: grid;
  place-items: center;
  /* background-image: url("../images/biomassbgimg.jpg");
  background-size: cover; */
  padding-top: 98px;
}

.biomassContainer {
  box-shadow: 2px 2px 7px #bbb;
  padding: 20px 10px;
  width: 60%;
  background-color: white;
  border-radius: 10px;
  margin: 5px;
}

.biomassDiv {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.sfe-extraction-Div {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.homeButton {
  font-size: 24px;
  padding-right: 40px;
}

.file-input-container {
  position: relative;
  overflow: auto;
  display: inline-block;
  width: 100%;
  max-width: 200px;
}

.file-input-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}


.custom-file-label {
  display: block;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

.pad-left {
  padding-left: 35px;
}

.pad-left1 {
  padding-left: 50px;
}

.dropdownStyle {
  border: none;
  padding: 5px;
  outline: none;
}

.batchNoStyling {
  font-size: 20px;
  color: black;
  font-weight: 500;
  padding-bottom: 10px;
}

/* supplier styling */

.supplierCoaContainer {
  /* display: block;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  box-shadow: 2px 2px 7px #bbb;
  padding: 20px 10px;
  width: 70%;
  background-color: white;
  border-radius: 10px;
}
.supplierCoaDiv{
width: 90%;
margin-left: auto;
margin-right: auto;
}
.sub-title{
  text-align: center;
  color: #000;
  font-weight: 500;
  padding: 10px;
  font-size: 16px;
}
.inputStyle1{
  width: 80px;
  padding: 5px;
  outline: none;
}
.input-title{
  padding: 10px;
  flex: auto;
}
.aling-right{
  text-align: right;
  display: flex;
}

/* Styling the fieldset  */
fieldset {
  padding: 0 10px !important;
  border: 1px solid #606060 !important;
  border-radius: 3px;
  width: 100%;
  max-width: 200px;

  input,
  select {
    height: 25px;
    margin-left: -12px;
    border: 0;
    width: 100%;
  }

  input:focus-visible,
  select:focus-visible {
    outline: 0;
  }

}
  /* Style the number input */
  input[type="number"] {
    /* Remove default arrows in Firefox */
    -moz-appearance: textfield;
    /* Hide the up and down arrows in other browsers */
    appearance: none;
    -webkit-appearance: none;
    /* Optional: Add custom styling to make it look consistent */
    border: 1px solid #787878;
    padding: 5px;

  }

  /* Style the spinner buttons (up and down arrows) */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    display: none;
  }

  input[type="text"] {
    /* Remove default arrows in Firefox */
    -moz-appearance: textfield;
    /* Hide the up and down arrows in other browsers */
    appearance: none;
    -webkit-appearance: none;
    /* Optional: Add custom styling to make it look consistent */
    border: 1px solid #787878;
    padding: 5px;

  }
   /* Style the spinner buttons (up and down arrows) */
   input[type="text"]::-webkit-inner-spin-button,
   input[type="text"]::-webkit-outer-spin-button {
     display: none;
   }
   button:hover{
    cursor: pointer;
   }
  .home-btn{
    border: none;
    background: white;
  }
  .searchicon{
    font-size: 26px;
    padding-top: 10px;
  }
  .input-grid{
    display: grid;
    place-items: center;
  }
  .flex-auto{
    flex: auto;
  }
  .inputFlex{
    display: flex;
  }


  /* popup styles */

.popup {
  /* display: none; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: none;
  justify-content: center;
  align-items: center;
}

.popup2 {
  /* display: none; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: none;
  justify-content: center;
  align-items: center;
}

.popup3 {
  /* display: none; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: none;
  justify-content: center;
  align-items: center;
}

.popup4 {
  /* display: none; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: none;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.logistic-metrial{
  display: grid;
  place-items: center;
  /* width: 200px; */
  font-size: 16px;
  font-weight: 500;
  padding-right: 90px;
}
.display-flex-coa{
  display: flex;
}
.display-flex-span{
  display: flex;
  place-items: center;
}


/* popup cancel save button styling */
.popup-btns{
  color: white;
  background: black;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px 15px;
  flex: auto;
}
.popup-btns-flex{
  display: flex;
}


/* Loader  */
.dot-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 15px;
  height: 15px;
  margin: 0 5px;
  background-color: #3498db;
  border-radius: 50%;
  animation: loading 1s infinite alternate;
}

.dot:nth-child(2) {
  background-color: #07f32e;
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  background-color: #992020;
  animation-delay: 0.4s;
}
.dot:nth-child(4) {
  background-color: #00B247;
  animation-delay: 0.6s;
}
.dot:nth-child(5) {
  background-color: #8f87d6;
  animation-delay: 0.8s;
}
@keyframes loading {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* loader code End */

.select-batch-style{
  /* text-align: center; */
  color: #3812b3;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 5px;
}

/* Media Queries */
@media only screen and (min-width: 20px) and (max-width: 1340px) {
  .extractionContainer{
    box-shadow: 2px 2px 7px #bbb;
    padding: 20px 10px;
    width: 80%;
    background-color: white;
    border-radius: 10px;
    margin: 5px;
  }
}
@media only screen and (min-width: 1341px) and (max-width: 2340px) {
  .extractionContainer{
    box-shadow: 2px 2px 7px #bbb;
    padding: 20px 10px;
    width: 60%;
    background-color: white;
    border-radius: 10px;
    margin: 5px;
  }
}


/* Report page styling Start*/
.reporttitle{
  width: 100%;
  margin-top: 5px;
  background-color: white;
  color: #000;
  z-index: 9999;
  flex-wrap: wrap;
  margin-bottom: 5px;
  padding: 5px;
}

.reportContainer{
  padding: 20px;
  background-color: white;
  width: 90%;
  /* height: 100vb; */
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
}

.batchinfo{
  font-size: 14px;
  font-weight: 400;
  padding: 2px;
}
.header-styling{
  padding: 0px 10px;
}
.pdfPage-styling{
  padding: 20px;
}
.download-div{
  text-align: end;
  padding-left: 20px;
}
.dwnld-btn{
  width: 100%;
  max-width: 170px;
  color: white;
  background: black;
  border: none;
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  font-size: 16px;
  /* background: linear-gradient(rgb(0, 119, 8), rgb(43, 255, 0)); */
}

.reportheading {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 10px;

}
/* Report page styling End*/

.home-btn-span{
  display: flex;
  place-items: center;
}
.btn-width{
  width: 220px;
}
.addTitle-batch {
  font-size: 30px;
  font-weight: 600;
  /* padding: 21px 50px; */
  text-align: center;
  /* padding: 21px 150px 21px 0px; */
  flex: auto;
  display: grid;
  place-items: center;
}
