.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Login Page */
.main {
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 98px;
  /* padding-bottom: 90px; */
  /* background-color: #e1e3eb; */
  height: 100vb;
  align-items: center;
}
.sub-main {
  display: flex;
  justify-content: center;
  /* height: 470px; */
  width: 100%;
  max-width: 400px;
  /* box-shadow: 11px 12px 13px 12px rgb(207, 207, 207); */
  box-shadow: 2px 2px 7px #bbb;
  padding-top: 30px;
  border-radius: 20px;
  background-color: white;
}
.imgs {
    /* padding-top: 20px; */
    justify-content: center;
    display: flex;
  }
.container-image {
  /* background-color: rgb(223, 221, 221);  */
  border-radius: 150px;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 115px;
  width: 115px;
}
.profile {
  height: 80px;
  width: 105px;
  border-radius: 50%;
} 

.input-box{
  width: 100%;
    /* max-width: 409px; */
    height: 50px;
    border-radius: 20px;
    /* box-shadow: inset 0px 0px 25px 0px #888; */
    border: none;
    outline: none;
    background-color: #e8f0fe;
}

.name{
    padding-left: 20px;
    font-size: 16px;
}
.second-input{
  margin-top: 20px;
  background: #e8f0fe;
  border-radius: 20px;
}
.password-input{
  width: 90%;
  outline: none;
  border: none;
}

.log-button{
  width: 100%;
  /* max-width: 300px; */
  height: 50px;
  border-radius: 60px;
  background-color: #000;
  color: white;
  font-size: 20px;
  border: none;
}
.login-button{
    padding-top: 25px;
}

.forgot-btn{
  background: none;
  border: none;
  padding-top: 20px;
  color: #3c3cb8;
  font-weight: 500;
  padding-bottom: 20px;
}

 