body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.page1-bg{
  background-color: black;
}
.page2-bg{
  background-color: #85abae;
}
.login-bg{
  background-image: url("./images/loginbackground.jpg");
  background-size: cover;
}
.loginPage-bg{
  background-image: url("./images/loginpage.jpg");
  background-size: cover;
}
.newbatch-bg{
  background-image: url("./images/newbatchbgimg.jpg");
  background-size: cover;
}
.other-bg{
  background-image: url("./images/biomassbgimg.jpg");
  background-size: cover;
}